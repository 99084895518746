import React from "react"

import Layout from "../../components/layout/layout-stp"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import 'swiper/swiper.scss';
import { Link, graphql } from "gatsby"
import { Swiper, SwiperSlide } from 'swiper/react';
import * as BannerStyle from '../../components/banner/banner.module.scss'
import { GatsbyImage } from "gatsby-plugin-image"

const IndexST = ({data}) => (
  <Layout>
    <Seo title="Welcome" bodyClass="stp"/>
    
  <div className="column column--100">

  <Swiper
      spaceBetween={24}
      slidesPerView={1}
      loop={true}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      className={BannerStyle.banner}

    >
      <SwiperSlide><GatsbyImage className={BannerStyle.banner__image} image={data.banner_avekode.childImageSharp.gatsbyImageData}/></SwiperSlide>
      <SwiperSlide><Link to='/stp/store'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_thatwasclever.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/stp/store'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_tees.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
         
     
    </Swiper>

    <div className="grid grid--home">

    <div>
      <span className={style.titleLowercase}>Welcome to STP</span>
      
      <p>SPECYAL T PRODUCTIONS are a one-of-a-kind music company who's signature are Personalized Custom Songs! Established back in 2005 by the unprecedented talent known as Hip Hop / R&B artist Specyal T, this unique company specializes in Custom Production, Songwriting & Artist Development! Not to be compared to anyone else, they strive to be completely original in everything they do. Giving their clients and fan's ears something to hear that they haven't heard before!</p>

      <p>It all began in 2002 with a concept, a ukulele and a tape deck, just writing songs for friend's birthdays. Now over 15 years later and 100+ songs created, SPECYAL T PRODUCTIONS signature sound has risen to a whole new level!</p>

      <p>Each song is designed to highlight the events of a client's chosen theme in that year. We like to call them "musical time capsules". For example if a client requests a b-day song for someone's 21st , which is also a milestone, we would take all the info provided to us, turn it into catchy lyrics, layer it with the person's favorite type of music and end it off with a personal dedication to them! In every person's life, each year brings something different so it doesn't matter if you have already had 1 song done or 10, there is always something NEW and UNIQUE to say! Along with birthdays other popular themes are: Parent-To-Be's, Weddings, and even Memorials.</p>

      <p>Looking for a unique and affordable gift idea for a special occasion? Maybe you just want a funny memory captured in song? Well you've come to the right place! We're the #1 Personalized Custom Song Service! What are you waiting for?</p>
      
      <p>Contact us today and let's get started because...</p>
      
      <span className={style.subtitleLowercase}>A Song From Us Is Always Special!</span>
    </div>
    <div>
      <div className={style.homeFeatures}>
          <span className={style.homeFeatures__header}>Features</span>
          <span className={style.homeFeatures__subheader}>Genres Include</span>
          <span className={style.homeFeatures__text}>*Hip Hop<br/>
          *RnB<br/>
          *Popures</span>
          
          
          <span className={style.homeFeatures__subheader}>Themes Include</span>
          <span className={style.homeFeatures__text}> *Birthdays<br/>
          •Parents-To-Be's<br/>
          *Wedding Songs</span>
         

          <span className={style.homeFeatures__text}>A personal DEDICATION to the recipient always done by our artists!</span>

          <span className={style.homeFeatures__text}>*A LIVE PERFORMANCE of the song (in select cities)</span>

          <span className={style.homeFeatures__text}>We also do MULTIPLES!</span>
        </div>
      </div>
    </div>
  </div>
  </Layout>
)

export default IndexST

export const query = graphql`
  query {
    banner_avekode: file(relativePath: {eq: "banners/banner-avenue-kode.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_thatwasclever: file(relativePath: {eq: "banners/banner_thatwasclever.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_tees: file(relativePath: {eq: "banners/banner-tees.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
